// gatsby-browser.js
import "./src/css/normalize.css"
import "./src/css/style.css"
import './src/styles/global.css'
import '@fontsource/inter';

import { Helmet } from "react-helmet";

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    window.Trengo = window.Trengo || {};
    window.Trengo.key = 'ZzPnZPtATHqtkZG'; // Jouw Trengo sleutel
    window.Trengo.sound = false; // Zet geluid uit
    window.Trengo.render = false; // Voorkom automatisch renderen bij het laden

    const script = document.createElement("script");
    script.src = "https://static.widget.trengo.eu/embed.js";
    script.async = true;

    script.onload = () => {
      console.log("Trengo script is geladen.");

      // Dempt audio in de widget
      const muteTrengoAudio = () => {
        const audioElements = document.querySelectorAll('#trengo-web-widget audio');
        audioElements.forEach(audio => {
          audio.muted = true;
          audio.volume = 0;
        });
      };

      // Roep de mute functie aan zodra de widget klaar is
      window.Trengo.on_ready = function() {
        console.log('Trengo widget is ready, audio dempen');
        muteTrengoAudio();
      };

      // Verberg de widget zodra deze wordt gesloten
      window.Trengo.on_close = function() {
        const widget = document.querySelector('#trengo-web-widget');
        if (widget) {
          widget.style.visibility = 'hidden'; // Verberg de widget na het sluiten
        }
      };
    };

    script.onerror = () => {
      console.error("Fout bij het laden van het Trengo script.");
    };

    document.head.appendChild(script);

    // Voeg Google Fonts Inter toe
    const link = document.createElement("link");
    link.href = "https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }
};